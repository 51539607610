.app {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  #main-canvas {
    width: 100%;
    height: 100%;
    cursor: grab;
  }

  #main-canvas:active {
    cursor: grabbing;
  }

  button {
    cursor: pointer;
  }

  .icon {
    font-size: 20px;
    vertical-align: top;
    margin-right: 16px;
  }

  .checkboxes {
    position: absolute;
    right: 2rem;
    top: 2rem;
    display: flex;
    gap: 16px;
    transform: translateX(-500px);
    transition-duration: 0.25s;

    &.hidden {
      transform: translateX(0px);
    }

    @media screen and (max-width: 450px) {
      transform: none;
    }
  }

  .side-bar {
    box-sizing: border-box;
    position: absolute;
    height: 100%;
    width: 500px;
    overflow: scroll;
    right: 0;
    top: 0;
    color: white;
    padding: 1rem;
    background-color: #252525;
    border: solid 12px #ffc600;
    transform: translateX(0);
    transition-duration: 0.25s;

    overflow-x: hidden;
    overflow-y: auto;

    &.hidden {
      transform: translateX(100%);
    }

    &::-webkit-scrollbar {
      width: 7px !important;
      background: none !important;
    }

    &::-webkit-scrollbar-thumb {
      background: #eeeeee !important;
      // opacity: .4 !important;
    }

    &::-webkit-scrollbar-track {
      // background-color: #ffc60055 !important;
      // opacity: .4;
      background: none;
    }

    @media screen and (max-width: 450px) {
      transform: translateY(0);
      &.hidden {
        transform: translateY(100%);
      }
    }

    button {
      position: relative;
    }

    button:disabled::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($color: #000000, $alpha: 0.7);
      cursor: not-allowed;
    }

    .header {

      height: 40px;

      span {
        font-weight: 800;
        font-size: 16px;
      }

      .close-sidebar {
        position: absolute;
        top: 1rem;
        right: 1rem;
        border: none;
        background: none;
        color: white;

        .icon {
          margin: 0;
        }
      }
    }

    .edit-tile {
      width: 100%;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      border: none;
      background-color: #ffc600;
      padding: 15px;
      margin-bottom: 27px;

      .icon {
        margin-right: 5px;
      }
    }

    .login-with-metamask {
      
      margin-bottom: 10px !important;

      .metamask-icon {
        width: 20px;
        height: 20px;
        vertical-align: top;
        margin-right: 5px;
        margin-top: -2px;
      }
    }

    @media screen and (max-width: 600px) {
      top: 60%;
      height: 40%;
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        width: 7px !important;
        background: none !important;
      }

      &::-webkit-scrollbar-thumb {
        background: #eeeeee !important;
        // opacity: .4 !important;
      }

      &::-webkit-scrollbar-track {
        // background-color: #ffc60055 !important;
        // opacity: .4;
        background: none;
      }
    }

    .split {
      border: solid 3px #ffc600;
      margin-top: 2rem;
      margin-bottom: 2rem;
      background-color: #ffc600;
    }

    .title {
      font-weight: 800;

      .eth-icon {
        width: 20px;
        height: 20px;
        font-size: 20px;
        vertical-align: top;
        margin-right: 16px;
        filter: invert(1);        

      }
    }

    .row {
      margin-top: 24px;
      margin-bottom: 24px;
      word-break: break-all;
      padding-left: 20px;
      display: flex;
      flex-direction: row;
    }

    .key {
      font-weight: 700;
      font-size: 14px;
      line-height: 1rem;
      margin-right: 10px;
    }

    .value {
      font-weight: 400;
      font-size: 14px;
      line-height: 1rem;
      display: flex;
      flex-direction: row;
      width: 0;
      flex-grow: 1;
    }

    .copy {
      background: none;
      border: none;
      color: #fff;
      text-align: right;
      flex-grow: 1;
      outline: none;

      span {
        width: 1rem;
      }
    }

    .token-id {
      text-decoration: none;
      color: #8080ff;
    }

    .price {
      display: flex;
      margin-top: 24px;
      margin-bottom: 3px;
      border: solid 1px #ffc600;

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input {
        flex-grow: 1;
        border: none;
        background: none;
        font-weight: 700;
        font-size: 14px;
        line-height: 1rem;
        color: #ffc600;
        padding-top: 15px;
        padding-bottom: 14px;
        padding-left: 10px;
        outline: none;

        @media screen and (max-width: 600px) {
          width: 50%;
        }
      }

      .unit {
        font-weight: 700;
        font-size: 14px;
        line-height: 1rem;
        display: flex;
        align-items: center;
        padding-right: 17px;
        color: #ffc600;
      }

      button {
        font-weight: 700;
        font-size: 14px;
        line-height: 1rem;
        border: none;
        background-color: #ffc600;
        padding-left: 28px;
        padding-right: 26px;
      }
    }

    .price-description {
      font-style: italic;
      font-weight: 400;
      font-size: 11px;
      line-height: 13px;
    }

    .history-box {
      border: solid 1px #ffffff;

      .tabs {
        button {
          font-weight: 700;
          font-size: 14px;
          width: 50%;
          padding: 0.5rem;
          border: none;
          background: none;
          color: #cacaca;
          border-bottom: solid 2px #cacaca;

          &.selected {
            color: #cacaca;
            border-bottom: solid 2px #ffc600;
          }
        }
      }
    }

    .log {
      &.history {
        .icon {
          flex-grow: 1;
          text-align: right;
          margin-right: 0;
        }
      }

      .log-item {
        margin: 2rem;
        margin-top: 1rem;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
      }

      .content {
        display: flex;
        margin-top: 4px;

        .arrow {
          width: 4rem;
          text-align: center;
        }
      }

      .timestamp {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #b0b0b0;
        padding-top: 2px;
      }
    }

    .donation {
      .box {
        border: solid 1px #ffffff;
        margin: 1rem;
        display: flex;

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        input {
          border: none;
          background: none;
          flex-grow: 1;
          outline: none;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          padding-top: 9px;
          padding-bottom: 9px;
          padding-left: 17px;
          color: #ffffff;

          @media screen and (max-width: 600px) {
            width: 50%;
          }
        }

        .unit {
          font-weight: 700;
          font-size: 14px;
          line-height: 16px;
          display: flex;
          align-items: center;
          margin-right: 19px;
        }

        button {
          border: none;
          background: none;
          background-color: #ffffff;
          font-weight: 700;
          font-size: 14px;
          line-height: 16px;
          padding-left: 31px;
          padding-right: 31px;
        }
      }
      .icon {
        margin: 0;
        margin-left: 1rem;
      }
      .open-etherscan {
        text-decoration: none;
        color: #ffffff;
      }
    }

    .tabs {
      height: 34px;

      @media screen and (max-width: 450px) {
        height: 50px;
      }

      button {
        @media screen and (max-width: 450px) {
          vertical-align: middle;
          height: 50px;
        }
      }
    }
  }

  .footer {
    font-weight: 400;
    font-size: 11px;
    color: #cacaca;
    line-height: 13px;
    text-align: center;
    
    margin-top: 24px;
    margin-bottom: 3px;

    a {
      color: #cacaca;
    }

  }

  .dim-screen {

    position:fixed;
    padding:0;
    margin:0;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background:rgba(255,255,255,0.5);

  }

  .spinner {

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
     
    .cssload-thecube {
      width: 73px;
      height: 73px;
      margin: 0 auto;
      margin-top: 49px;
      position: relative;
      transform: rotateZ(0deg);
        -o-transform: rotateZ(0deg);
        -ms-transform: rotateZ(0deg);
        -webkit-transform: rotateZ(0deg);
        -moz-transform: rotateZ(0deg);
    }
    .cssload-thecube .cssload-cube {
      position: relative;
      transform: rotateZ(0deg);
        -o-transform: rotateZ(0deg);
        -ms-transform: rotateZ(0deg);
        -webkit-transform: rotateZ(0deg);
        -moz-transform: rotateZ(0deg);
    }
    .cssload-thecube .cssload-cube {
      float: left;
      width: 50%;
      height: 50%;
      position: relative;
      transform: scale(1.1);
        -o-transform: scale(1.1);
        -ms-transform: scale(1.1);
        -webkit-transform: scale(1.1);
        -moz-transform: scale(1.1);
    }
    .cssload-thecube .cssload-cube:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgb(255,200,0);
      animation: cssload-fold-thecube 2.76s infinite linear both;
        -o-animation: cssload-fold-thecube 2.76s infinite linear both;
        -ms-animation: cssload-fold-thecube 2.76s infinite linear both;
        -webkit-animation: cssload-fold-thecube 2.76s infinite linear both;
        -moz-animation: cssload-fold-thecube 2.76s infinite linear both;
      transform-origin: 100% 100%;
        -o-transform-origin: 100% 100%;
        -ms-transform-origin: 100% 100%;
        -webkit-transform-origin: 100% 100%;
        -moz-transform-origin: 100% 100%;
    }
    .cssload-thecube .cssload-c2 {
      transform: scale(1.1) rotateZ(90deg);
        -o-transform: scale(1.1) rotateZ(90deg);
        -ms-transform: scale(1.1) rotateZ(90deg);
        -webkit-transform: scale(1.1) rotateZ(90deg);
        -moz-transform: scale(1.1) rotateZ(90deg);
    }
    .cssload-thecube .cssload-c3 {
      transform: scale(1.1) rotateZ(180deg);
        -o-transform: scale(1.1) rotateZ(180deg);
        -ms-transform: scale(1.1) rotateZ(180deg);
        -webkit-transform: scale(1.1) rotateZ(180deg);
        -moz-transform: scale(1.1) rotateZ(180deg);
    }
    .cssload-thecube .cssload-c4 {
      transform: scale(1.1) rotateZ(270deg);
        -o-transform: scale(1.1) rotateZ(270deg);
        -ms-transform: scale(1.1) rotateZ(270deg);
        -webkit-transform: scale(1.1) rotateZ(270deg);
        -moz-transform: scale(1.1) rotateZ(270deg);
    }
    .cssload-thecube .cssload-c2:before {
      animation-delay: 0.35s;
        -o-animation-delay: 0.35s;
        -ms-animation-delay: 0.35s;
        -webkit-animation-delay: 0.35s;
        -moz-animation-delay: 0.35s;
    }
    .cssload-thecube .cssload-c3:before {
      animation-delay: 0.69s;
        -o-animation-delay: 0.69s;
        -ms-animation-delay: 0.69s;
        -webkit-animation-delay: 0.69s;
        -moz-animation-delay: 0.69s;
    }
    .cssload-thecube .cssload-c4:before {
      animation-delay: 1.04s;
        -o-animation-delay: 1.04s;
        -ms-animation-delay: 1.04s;
        -webkit-animation-delay: 1.04s;
        -moz-animation-delay: 1.04s;
    }

    @keyframes cssload-fold-thecube {
      0%, 10% {
        transform: perspective(136px) rotateX(-180deg);
        opacity: 0;
      }
      25%,
            75% {
        transform: perspective(136px) rotateX(0deg);
        opacity: 1;
      }
      90%,
            100% {
        transform: perspective(136px) rotateY(180deg);
        opacity: 0;
      }
    }

    @-o-keyframes cssload-fold-thecube {
      0%, 10% {
        -o-transform: perspective(136px) rotateX(-180deg);
        opacity: 0;
      }
      25%,
            75% {
        -o-transform: perspective(136px) rotateX(0deg);
        opacity: 1;
      }
      90%,
            100% {
        -o-transform: perspective(136px) rotateY(180deg);
        opacity: 0;
      }
    }

    @-ms-keyframes cssload-fold-thecube {
      0%, 10% {
        -ms-transform: perspective(136px) rotateX(-180deg);
        opacity: 0;
      }
      25%,
            75% {
        -ms-transform: perspective(136px) rotateX(0deg);
        opacity: 1;
      }
      90%,
            100% {
        -ms-transform: perspective(136px) rotateY(180deg);
        opacity: 0;
      }
    }

    @-webkit-keyframes cssload-fold-thecube {
      0%, 10% {
        -webkit-transform: perspective(136px) rotateX(-180deg);
        opacity: 0;
      }
      25%,
            75% {
        -webkit-transform: perspective(136px) rotateX(0deg);
        opacity: 1;
      }
      90%,
            100% {
        -webkit-transform: perspective(136px) rotateY(180deg);
        opacity: 0;
      }
    }

    @-moz-keyframes cssload-fold-thecube {
      0%, 10% {
        -moz-transform: perspective(136px) rotateX(-180deg);
        opacity: 0;
      }
      25%,
            75% {
        -moz-transform: perspective(136px) rotateX(0deg);
        opacity: 1;
      }
      90%,
            100% {
        -moz-transform: perspective(136px) rotateY(180deg);
        opacity: 0;
      }
    }
  }
}
