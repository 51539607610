@mixin nonDraggable {
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.editor {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #252525;
  z-index: 1;

  .button-close {
    position: absolute;
    top: 2rem;
    right: 2rem;
    background-color: #f00;
    color: #fff;
    border: none;

    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    .icon {
      margin: 0;
    }
  }

  .box {
    border: 1px solid #ffffff;
    box-sizing: border-box;
    padding: 13px 12px;
    background: none;
    color: #ffffff;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 8px;
    width: 333px;
  }

  .color-picker {
    position: absolute;
    left: 3rem;
    top: 50%;
    transform: translateY(-50%);

    * {
      @include nonDraggable();
    }

    .sketch-picker {
      background: none !important;
      border: none !important;
      margin: 0 !important;
      padding: 0 !important;
      box-shadow: none !important;
      width: 342px !important;

      > div:nth-of-type(1) {
        width: 333px !important;
        margin-bottom: 9px !important;
      }

      .flexbox-fix {
        border: none !important;
        margin: 0 !important;
        padding: 0 !important;

        * {
          margin: 0 !important;
          padding: 0 !important;
          box-shadow: none !important;
          border-radius: 0 !important;
        }
      }

      .flexbox-fix:nth-of-type(2) {
        padding-right: 9px !important;
        > div:nth-child(1) > div {
          height: 48px !important;
          > div > div > div {
            height: 100% !important;
            > div {
              height: 100% !important;
            }
          }
        }
        > div:nth-child(2) {
          width: 48px !important;
          height: 48px !important;
          margin-left: 9px !important;
        }
      }

      .flexbox-fix:nth-of-type(3) {
        display: none !important;
      }

      .flexbox-fix:nth-of-type(4) {
        justify-content: left;

        > div {
          width: 48px !important;
          height: 48px !important;
          margin: 9px 9px 0px 0px !important;
          div {
            border: none !important;
          }
        }
      }

      input {
        outline: none !important;
        border: solid 1px #fff !important;
        background: none !important;
        box-shadow: none !important;
        color: #ffffff !important;
        font-weight: 400 !important;
        font-size: 16px !important;
        line-height: 19px !important;
        text-align: center;
      }
    }
  }

  .toolbar {
    position: absolute;
    right: 3rem;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;

    * {
      @include nonDraggable();
    }

    .size {
      display: flex;

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input {
        border: none;
        background: none;
        color: #ffffff;
        outline: none;
        flex-grow: 1;
        font-weight: 400;
        font-size: 16px;
        text-align: center;
      }
    }

    .position {
      display: flex;
      .empty {
        flex-grow: 1;
      }
    }

    .save-area {
      display: flex;
      gap: 8px;

      .box {
        width: 0;
        flex-grow: 1;
      }
    }
  }

  .pixel-area {
    padding: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .row {
      margin: 0;
      padding: 0;
      display: flex;
      .pixel {
        margin: 0;
        padding: 0;
        width: 20px;
        height: 20px;
        border: solid 1px rgba($color: #888, $alpha: 0.2);
      }
    }
  }
}
